import utils from "../utils";

/* -------------------------------------------------------------------------- */
/*                                 Line Chart                                 */
/* -------------------------------------------------------------------------- */

const chartLineDosesInit = () => { 
  /*-----------------------------------------------
  |   Helper functions and Data 
  -----------------------------------------------*/
  const chartData = [
    3,
    1,
    4,
    1,
    5,
    9,
    2,
    6,
    5,
    3,
    5,
    8
  ];
  const labels = [
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
  ];

  function getLastSevenDays() { 
    var newLabels = [];
    var i=6;
    while(i>=0) {
      var today = new Date();
      var workingDate = new Date();
      workingDate.setDate(today.getDate()-i);
      newLabels.push(workingDate.toDateString());
      i = i-1;
    }
    return newLabels;
  }

  var lastSevenDays = getLastSevenDays();

  function getLastThirtyDays() {
    var newLabels = [];
    var i=29;
    while(i>=0) {
      var today = new Date();
      var workingDate = new Date();
      workingDate.setDate(today.getDate()-i);
      newLabels.push(workingDate.toDateString());
      i = i-1;
    }
    return newLabels;
  }

  var lastThirtyDays = getLastThirtyDays();

  /*-----------------------------------------------
  |   Line Chart
  -----------------------------------------------*/
  const chartLine = document.getElementById("chart-line-doses");

  if (chartLine) {
    const getChartBackground = (chart) => {
      const ctx = chart.getContext("2d");
      if (localStorage.getItem("theme") === "light") {
        const gradientFill = ctx.createLinearGradient(0, 0, 0, 250);
        gradientFill.addColorStop(0, "rgba(255, 255, 255, 0.3)");
        gradientFill.addColorStop(1, "rgba(255, 255, 255, 0)");
        return gradientFill;
      } else {
        const gradientFill = ctx.createLinearGradient(
          0,
          0,
          0,
          ctx.canvas.height
        );
        gradientFill.addColorStop(
          0,
          utils.rgbaColor(utils.getColors().primary, 0.5)
        );
        gradientFill.addColorStop(1, "transparent");
        return gradientFill;
      }
    };

    // TODO: change tooltips to reflect new data points for last 7 and 30 days


    const dashboardLineChart = utils.newChart(chartLine, {
      type: "line",
      data: {
        labels: labels.map((label) => label.substring(0, label.length - 3)),
        datasets: [
          {
            borderWidth: 2,
            data: chartData,
            borderColor:
              localStorage.getItem("theme") === "dark"
                ? utils.getColors().primary
                : utils.settings.chart.borderColor,
            backgroundColor: getChartBackground(chartLine),
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          mode: "x-axis",
          xPadding: 20,
          yPadding: 10,
          displayColors: false,
          callbacks: {
            title: function(tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']];
            },
            label: function(tooltipItem, data) {
              return data['datasets'][0]['data'][tooltipItem['index']];
            },
          },
        },
        hover: { mode: "label" },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                show: true,
                labelString: "Month",
              },
              ticks: {
                fontColor: utils.rgbaColor("#fff", 0.7),
                fontStyle: 600,
              },
              gridLines: {
                color: utils.rgbaColor("#fff", 0.1),
                zeroLineColor: utils.rgbaColor("#fff", 0.1),
                lineWidth: 1,
              },
            },
          ],
          yAxes: [{ display: false }],
        },
      },
    });

    document
      .querySelector("#dashboard-chart-select")
      ?.addEventListener("change", (e) => {
        const LineDB = {
          today: chartData
          ,
          sevenDays: [1, 2, 3, 4, 5, 6, 7]
          ,
          thirtyDays: [1, 0, 2, 1, 2, 1, 1, 0, 0, 1, 0, 2, 1, 0, 2, 1, 2, 1,
            1, 0, 0, 0, 0, 1, 2, 3, 4, 5, 6, 7]
          ,
          // sevenDays: [2, 1, 2, 3, 4, 5, 6].map((d) =>
          //   (d * 3.14).toFixed(2)
          // ),
          // thirtyDays: [1, 0, 2, 1, 2, 1, 1, 0, 0, 1, 0, 2, 1, 0, 2, 1, 2, 1,
          //   1, 0, 0, 1, 0, 2, 1, 2, 3, 4, 5, 6].map((d) =>
          //   (d * 3.14).toFixed(2)
          // ),
        };
        const LineXAxisLabels = {
          today: labels.map((label) => label.substring(0, label.length - 3))
          ,
          sevenDays: lastSevenDays.map((label) => label.substring(4, label.length-5))
          ,
          thirtyDays: lastThirtyDays.map((label) => label.substring(4, label.length-5))
          ,
        };
        const CurrentLabel = {
          today: "Today: "
          ,
          sevenDays: "Last 7 Days: "
          ,
          thirtyDays: "Last 30 Days: "
          ,
        };
        const PreviousLabel = {
          today: "Yesterday: "
          ,
          sevenDays: "Previous 7 Days: "
          ,
          thirtyDays: "Previous 30 Days: "
          ,
        };
        // dashboardLineChart.data.datasets[0].data.pop();
        // dashboardLineChart.update();
        // dashboardLineChart.data.datasets[0].data.push(LineDB[e.target.value]);
        // dashboardLineChart.data.datasets[0].data = [];
        //dashboardLineChart.data.datasets[0].data.pop();
        // LineDB[e.target.value].forEach((value) => {
        //   dashboardLineChart.data.datasets[0].data.push(value);
        // });
        dashboardLineChart.data.datasets[0].data = LineDB[e.target.value];
        dashboardLineChart.data.labels = LineXAxisLabels[e.target.value];
        document.getElementById("current-label").textContent = CurrentLabel[e.target.value];
        document.getElementById("previous-label").textContent = PreviousLabel[e.target.value];
        dashboardLineChart.update();
      });
    // change chart color on theme change
    const changeChartThemeColor = (borderColor) => {
      dashboardLineChart.data.datasets[0].borderColor = borderColor;
      dashboardLineChart.data.datasets[0].backgroundColor = getChartBackground(
        chartLine
      );

      dashboardLineChart.update();
    };

    // event trigger
    const themeController = document.body;

    themeController.addEventListener(
      "clickControl",
      ({ detail: { control, value } }) => {
        if (control === "theme") {
          if (value === "dark") {
            changeChartThemeColor(utils.getColors().primary);
          } else {
            changeChartThemeColor(utils.settings.chart.borderColor);
          }
        }
      }
    );
  }
};

export default chartLineDosesInit;
